const NarrativeNotesSecondEntry = () => {
    return (<div>
    <h1>On the subject of Narrative Control, Percption and Alteration</h1>
    <h3>Written by Zuki, Monarch of the Dawn Court</h3>
    <p>
        You've noticed that this previous character pattern didn't exist within your regular perception. These fractures in perception exist
        due to an instabilization of the narrative frame. These, in turn, are caused by the powerful disruptions the Boy Without A Heart
        is creating. These disruptions are caused by the intense fragilization of fae psyches, and are a perversion of the magics used by
        the Lark. If you go to Clementine, and tell her "I understand your heart", she'll tell you everything you need to know about the 
        Lark's powers and abilities. Building up knowledge about the Morpheans will be very useful. Clementine, specifically, is also
        aware of this second layer, and will know that you are aware, once you tell her those 4 words. 

        This phenomenon, where you notice things beyond regular perception, is called "narrative perception". Think of the series of 
        events your group is currently going through as a book, continuously being written as more events occur. This book has, in a sense,
        characters and words written "in the margins", or as notes from a narrator. You have the ability to notice these margin notes, 
        despite you being directly in the story. A small number of fae, such as me, after escaping Arcadia, didn't return to the mortal 
        world in a regular fashion. Instead, we were returned with a completely altered perception of the world, as if existing on a layer
        above. This allows me to observe things in a different manner, but also allows me to manipulate the things I see. 

        You have, over time, gained this narrative perception ability. This may bring you to believe that this means the events we're going
        through are pre-written, and we're all just characters in a play. While the second part is... not *entirely* wrong, the first part
        is false. The story is being written as it happens. Nothing's predetermined. At least, most things aren't. Due to events I don't
        fully understand, but that are directly related to the Boy Without A Heart's actions, this "narrative layer" is unraveling. And fast.

        I'll be able to tell you more once you speak to Clementine about this. She'll give you a way to get to the next part.
    </p>
    </div>
    );
  };
  
  export default NarrativeNotesSecondEntry;