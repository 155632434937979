const MilioFirstEntry = () => {
    return (<div>
    <p>Well, this is it! In a couple days, I'll be leaving the Grove and officially taking a seat under Flint! The troupe is so happy for me, this is
        so exciting! I'll be able to bring even more changes to things, while actually grounding myself, for once. The Lark's really great, but it's
        very intricately woven within the Wyrd, whereas I need a bit more.. reality, I guess? This whole thing about songs to heal is amazing, though.
        I'm leaving them with great knowledge, as well as their own Contract! I'll keep it for myself, as they asked, but I'll make sure to make use of
        it for good. 
    </p>

    <p>Fifth Seat... that's wiiiiild!</p>
    </div>
    );
  };
  
  export default MilioFirstEntry;