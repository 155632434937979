const NarrativeNotesFirstEntry = () => {
    return (<div>
    <h1>On the subject of Narrative Control, Percption and Alteration</h1>
    <h3>Written by Zuki, Monarch of the Dawn Court</h3>
    <p>
        As a preface, I should mention that this is the only method I've found so far of letting what I wish to say go through to you
        unfiltered. You should be able to deduce that, by finding this 17-character pattern where you found it, that I'm the one who 
        decides which songs, ultimately, are played to you. While I am most definitely on your side, I am also bound to the rules of the
        games we're stuck in.

        There's another 17-character pattern you'll be able to find, related to the songs currently in play. However, only those with eyes
        on the second layer of things will be able to notice it. Once you find that pattern, I'll tell you more.
    </p>
    </div>
    );
  };
  
  export default NarrativeNotesFirstEntry;