import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FirstEntry from "./pages/FirstEntry";
import SecondEntry from "./pages/SecondEntry";
import ThirdEntry from "./pages/ThirdEntry";
import LavenderSideEntry from "./pages/LavenderSideEntry";
import FirstEntryLark from "./pages/FirstEntryLark";
import SecondEntryLark from "./pages/SecondEntryLark";
import GOTVFirstEntry from "./pages/GOTVFirstEntry";
import GOTVSecondEntry from "./pages/GOTVSecondEntry";

import TruthAndTreacheryFirstEntry from "./pages/TruthAndTreacheryFirstEntry";





import Home from "./pages/Home";
import ThirdEntryLark from "./pages/ThirdEntryLark";
import FourthEntryLark from "./pages/FourthEntryLark";
import FifthEntryLark from "./pages/FifthEntryLark";
import SixthEntryLark from "./pages/SixthEntryLark";
import FifthEntry from "./pages/FifthEntry";
import SixthEntry from "./pages/SixthEntry";
import SeventhEntry from "./pages/SeventhEntry";
import FourthEntry from "./pages/FourthEntry";
import NarrativeNotesFirstEntry from "./pages/NarrativeNotesFirstEntry";
import NarrativeNotesSecondEntry from "./pages/NarrativeNotesSecondEntry";
import MilioFirstEntry from "./pages/MilioFirstEntry";




export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="/maelstrom" element={<FirstEntry />} />
          <Route path="/maelstrom/benjamin" element={<SecondEntry />} />
          <Route path="/maelstrom/storyteller" element={<ThirdEntry />} />
          <Route path="/maelstrom/robin" element={<LavenderSideEntry />} />
          <Route path="/maelstrom/rabbit" element={<FourthEntry />} />
          <Route path="/maelstrom/clementine" element={<FifthEntry />} />
          <Route path="/maelstrom/ace" element={<SixthEntry />} />
          <Route path="/maelstrom/rook" element={<SeventhEntry />} />
          <Route path="/absurdity" element={<FirstEntryLark />} />
          <Route path="/absurdity/hartebeest" element={<SecondEntryLark />} />
          <Route path="/absurdity/hound" element={<ThirdEntryLark />} />
          <Route path="/absurdity/memories" element={<FourthEntryLark />} />
          <Route path="/absurdity/maxwell" element={<FifthEntryLark />} />
          <Route path="/absurdity/framtidinni" element={<SixthEntryLark />} />
          <Route path="/watchtower" element={<GOTVFirstEntry />} />
          <Route path="/watchtower/carolina" element={<GOTVSecondEntry />} />
          <Route path="/thorns" element={<TruthAndTreacheryFirstEntry/>} />
          <Route path="/narration" element={<NarrativeNotesFirstEntry/>} />
          <Route path="/redaction" element={<NarrativeNotesSecondEntry/>} />
          <Route path="/melodious" element={<MilioFirstEntry/>} />

      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);